<template>
    <BaseContainer hideMenu="true">
        <span slot="principal">
            <v-container fluid style="background-color: #485461;
                background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card v-if="!finish" class="elevation-12">
                            <v-toolbar dark>
                                <v-spacer/>
                                ESQUECI MINHA SENHA
                                <v-spacer/>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        label="E-mail"
                                        ref="login"
                                        prepend-icon="person"
                                        type="text"
                                        v-model="email"
                                        autocomplete="username"
                                        @keypress.enter="$refs.password.focus()"
                                    />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    @click="sendCode"
                                    :loading="loading"
                                    class="white--text"
                                    color="#e50914"
                                    block
                                    large
                                >Enviar Código de Recuperação</v-btn>
                            </v-card-actions>
                            <v-card-actions>
                                <v-btn
                                    @click="goToLogin"
                                    block
                                    text
                                    small
                                >Voltar ao login</v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-card v-else class="elevation-12">
                            <v-toolbar dark color="success">
                                <v-spacer/>
                                <v-icon color="white" large>mdi-check-circle-outline</v-icon>
                                <v-card-title>E-mail Enviado!</v-card-title>
                                <v-spacer/>
                            </v-toolbar>
                            <v-card-text height="500" class="pa-8">
                                Um e-mail foi enviado para o seu endereço contendo um link para redefinir sua senha.<br><br>
                                Por favor, verifique sua caixa de entrada (e talvez sua pasta de spam) e siga as instruções fornecidas
                                para concluir o processo de redefinição de senha.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'

export default {
    components: {
        BaseContainer,
    },

    name: 'ForgotPassword',

    data: () => ({
        email: '',
        loading: false,
        finish: false,
    }),

    methods: {
        sendCode() {
            if (!this.email) {
                this.notify('Informe o e-mail', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('forgot-password', { email: this.email }).then(resp => {
                const data = resp.data;

                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.finish = true;
            })
            .catch(() => (this.notify('Verifique sua conexão com a internet', 'warning')))
            .finally(() => (this.loading = false));
        },

        goToLogin() {
            this.$router.push('/login');
        },
    },
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: 0 auto;
}
</style>
